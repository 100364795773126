import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { Grid, Paper, TextField, Button, MenuItem, Select, } from "@mui/material";
import "./AddNewService.css";
import { ToastContainer, toast } from "react-toastify";

const AddNewService = ({ onClose }) => {
  const { id } = useParams();
  const userId = id;
  const [projectDetails, setProjectDetails] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [userName, setUserName] = useState("");
  const [isCommentSectionOpen, setIsCommentSectionOpen] = useState(false);
  const [currentCommentRow, setCurrentCommentRow] = useState(null);
  const [comment, setComment] = useState("");
  const suggestionsRef = useRef(null);

  const [rows, setRows] = useState([
    {
      id: 1,
      userName: "",
      openDate: "",
      projectName: "",
      customerName: "",
      location: "",
      serviceMode: "",
      modelNumber: "",
      issue: "",
      warranty: "",
      serviceType: "",
      specifications: "",
      closeDate: "",
      comment: "",
    },
  ]);


  const handleserviceModeChange = (id, value) => {
    setRows((prevRows) =>
      prevRows.map((row) => (row.id === id ? { ...row, serviceMode: value, specifications: "" } : row))
    );
  };


  const handleSpecificationsChange = (id, value) => {
    setRows((prevRows) =>
      prevRows.map((row) => (row.id === id ? { ...row, specifications: value } : row))
    );
  };


  useEffect(() => {
    const fetchProjectDetails = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}serviceproject-names`
        );
        const result = await response.json();
        if (Array.isArray(result.serviceProjectDetails)) {
          setProjectDetails(result.serviceProjectDetails);
        } else {
          console.error("Invalid project details structure:", result);
          setProjectDetails([]);
        }
      } catch (error) {
        console.error("Error fetching project names:", error);
      }
    };

    fetchProjectDetails();
  }, []);

  const handleInputChange = (id, field, value) => {
    setRows((prevRows) =>
      prevRows.map((row) => (row.id === id ? { ...row, [field]: value } : row))
    );

    // Suggestion handling for project name
    if (field === "projectName") {
      const filteredSuggestions = projectDetails.filter((project) =>
        project.ProjectName?.toLowerCase().startsWith(value.toLowerCase().trim())
      );
      setSuggestions(filteredSuggestions);
    }

    // Reset fields if project name is cleared
    if (field === "projectName" && value === "") {
      setRows((prevRows) =>
        prevRows.map((row) =>
          row.id === id
            ? { ...row, location: "", consultant: "", contractor: "" }
            : row
        )
      );
      setSuggestions([]);
    }
  };

  const handleCommentClick = (rowId) => {
    setCurrentCommentRow(rowId);
    const existingComment = rows.find((row) => row.id === rowId)?.comment || "";
    setComment(existingComment);
    setIsCommentSectionOpen(true);
  };

  const handleSaveComment = () => {
    setRows((prevRows) =>
      prevRows.map((row) =>
        row.id === currentCommentRow ? { ...row, comment } : row
      )
    );
    setIsCommentSectionOpen(false);
  };

  const handleCancelComment = () => {
    setIsCommentSectionOpen(false);
  };

  const handleSuggestionClick = (suggestion) => {
    setRows((prevRows) =>
      prevRows.map((row) =>
        row.id === 1
          ? {
            ...row,
            projectName: suggestion.ProjectName,
            location: suggestion.location,
          }
          : row
      )
    );
    setSuggestions([]);
  };

  const handleClickOutside = (event) => {
    if (suggestionsRef.current && !suggestionsRef.current.contains(event.target)) {
      setSuggestions([]);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSubmit = async () => {
    // Ensure that the default date is set if it's not provided
    const updatedRows = rows.map((row) => ({
      ...row,
      openDate: row.openDate || new Date().toISOString().split("T")[0], // Default to today's date if empty
    }));

    console.log("Submitting rows:", updatedRows);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}add-service/${userId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ rows: updatedRows }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to add services");
      }

      const result = await response.json();
      console.log(result.message);

      // Reset rows to default values after successful submission
      setRows([
        {
          id: 1,
          userName: userName,
          openDate: "",
          projectName: "",
          customerName: "",
          location: "",
          serviceMode: "",
          modelNumber: "",
          issue: "",
          warranty: "",
          serviceType: "",
          specifications: "",
          closeDate: "",
          comment: "",
        },
      ]);

      // Show success toast
      toast.success("Service added successfully!");
    } catch (error) {
      console.error("Error submitting services:", error);
      toast.error("Failed to submit service details.");
    }
  };


  return (
    <div className="projecttracker-wrap">
      <Paper className="add-project">
        {rows.map((row) => (
          <Grid key={row.id} container spacing={2}>

            {/* <Grid item xs={6}>
                <TextField type='' value={rows[0].userName} onChange={(e) => handleInputChange(1, "userName", e.target.value)} disabled fullWidth />
              </Grid>
         */}
            <Grid item xs={6}>
              <TextField
                label="Open Date" // This acts as a placeholder for the date input
                type="date"
                value={row.quotedDate || new Date().toLocaleDateString("en-CA")} // Use "en-CA" format for yyyy-MM-dd
                onChange={(e) => handleInputChange(row.id, "openDate", e.target.value)}
                fullWidth
                InputProps={{
                  inputProps: {
                    max: new Date().toISOString().split("T")[0], // Disable future dates
                  },
                }}
                InputLabelProps={{
                  shrink: true, // Keeps the label when the date picker is open
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                type=""
                label="Project Name"
                value={row.projectName}
                onChange={(e) => handleInputChange(row.id, "projectName", e.target.value)}
                fullWidth
              />
              {suggestions.length > 0 && (
                <div ref={suggestionsRef} className="suggestions-dropdown">
                  {suggestions.map((suggestion, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => handleSuggestionClick(suggestion)}
                    >
                      {suggestion.ProjectName}
                    </MenuItem>
                  ))}
                </div>
              )}
            </Grid>
            <Grid item xs={6}>
              <TextField type='' label="Customer Name" value={row.customerName} onChange={(e) => handleInputChange(row.id, "customerName", e.target.value)} fullWidth />
            </Grid>
            <Grid item xs={6}>
              <TextField type='' label="Location" value={row.location} onChange={(e) => handleInputChange(row.id, "location", e.target.value)} fullWidth />
            </Grid>
            <Grid item xs={6}>
              <Select
                value={row.serviceMode || "Select Mode"}
                onChange={(e) => handleserviceModeChange(row.id, e.target.value)}
                fullWidth
              >
                <MenuItem value="Select Mode" disabled>
                  Select Mode
                </MenuItem>
                <MenuItem value="Technical Call">Technical Call</MenuItem>
                <MenuItem value="Site Visit">Site Visit</MenuItem>
                <MenuItem value="In Houe">In Houe</MenuItem>
              </Select>

              {row.serviceMode && (
                <TextField
                  label="Add Specifications"
                  value={row.specifications}
                  onChange={(e) => handleSpecificationsChange(row.id, e.target.value)}
                  placeholder="Enter specifications, separated by commas"
                  fullWidth
                  multiline
                />
              )}
            </Grid>
            <Grid item xs={6}>
              <TextField type='' label="Model Number" value={row.modelNumber} onChange={(e) => handleInputChange(row.id, "modelNumber", e.target.value)} fullWidth />
            </Grid>

            <Grid item xs={6}>
              <TextField type='' label="Issue" value={row.issue} onChange={(e) => handleInputChange(row.id, "issue", e.target.value)} fullWidth />
            </Grid>
            <Grid item xs={6}>
              <Select value={row.warranty || "Select warranty type"} onChange={(e) => handleInputChange(row.id, "warranty", e.target.value)} fullWidth >
                <MenuItem value="Select warranty type" disabled>
                  Select warranty type
                </MenuItem>
                <MenuItem value="In warranty">In Warranty</MenuItem>
                <MenuItem value="Expired">Expired</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={6}>
              <Select value={row.serviceType || "Select Type"} onChange={(e) => handleInputChange(row.id, "serviceType", e.target.value)} fullWidth >
                <MenuItem value="Select Type" disabled>
                  Select Type
                </MenuItem>
                <MenuItem value="Paid Service">Paid Service</MenuItem>
                <MenuItem value="Free Service">Free Service</MenuItem>
                <MenuItem value="To Be Discussed">To Be Discussed</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Close Date" // This acts as a placeholder for the date input
                type="date"
                value={row.closeDate}
                onChange={(e) => handleInputChange(row.id, "closeDate", e.target.value)}
                fullWidth
                InputLabelProps={{
                  shrink: true, // Keeps the label when the date picker is open
                }}
              />
            </Grid>
            <Grid item xs={12}>
              {row.comment ? ( // Check if a comment exists
                <div className="comment-preview-box">
                  <p className="comment-preview">{row.comment}</p> {/* Display saved comment as preview */}
                  <Button className="edit-btn" onClick={() => handleCommentClick(row.id)} >
                    Edit
                  </Button>
                </div>
              ) : (
                <Button className="comment-btn" onClick={() => handleCommentClick(row.id)} fullWidth style={{
                  width: "100%",
                  marginTop: "8px", // Add some spacing, optional
                }} >
                  Comment
                </Button>
              )}
              {isCommentSectionOpen && currentCommentRow === row.id && (
                <div className="comment-overlay">
                  <div className="comment-box">
                    <textarea className="comment-input" rows={5} value={comment} onChange={(e) => setComment(e.target.value)} />
                    <div className="button-box">
                      <Button className="submit-btn" onClick={handleSaveComment} > Save </Button>
                      <Button className="submit-btn" onClick={handleCancelComment} > Cancel </Button>
                    </div>
                  </div>
                </div>
              )}
            </Grid>
            <Grid item xs={6}>
              <Button className="submit-project-btn" onClick={handleSubmit} fullWidth>  Submit </Button>
            </Grid>
            <Grid item xs={6}>
              <Button className="cancle-project-btn" onClick={onClose} fullWidth> Cancel </Button>
            </Grid>
          </Grid>
        ))}
      </Paper>
      <ToastContainer /> {/* Add this line to include the ToastContainer */}
    </div>
  );
};

export default AddNewService;
