import React, { createContext, useState, useContext } from 'react';

// Create a context for holidays
const HolidayContext = createContext();

// Custom hook to use the holiday context
export const useHolidayContext = () => {
  const context = useContext(HolidayContext); 
  if (!context) {
    throw new Error('useHolidayContext must be used within a HolidayProvider');
  }
  return context;
};

// HolidayProvider component to provide holiday data to the context 
export const HolidayProvider = ({ children }) => {
  const [officialHolidays, setOfficialHolidays] = useState([]);
  const [publicHolidays, setPublicHolidays] = useState([]);

  return (
    <HolidayContext.Provider
      value={{
        officialHolidays,
        setOfficialHolidays,
        publicHolidays,
        setPublicHolidays,
      }}
    >
      {children}
    </HolidayContext.Provider>
  );
};
