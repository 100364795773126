import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { Table, Spinner, Alert, Button, Form } from 'react-bootstrap';
import './attendance.css';

const Attendance = () => {
  const [attendanceData, setAttendanceData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [empCode, setEmpCode] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [selectedDate, setSelectedDate] = useState(new Date());

  // Function to check if the day is a weekend (Saturday or Sunday)
  const isWeekend = (date) => {
    const day = new Date(date).getDay();
    return day === 0 || day === 6; // Sunday or Saturday
  };

  // Function to check if the day is the 2nd or 3rd Saturday
  const isSpecialSaturday = (date) => {
    const day = new Date(date);
    const dayOfMonth = day.getDate();
    const month = day.getMonth();
    
    const firstSaturday = new Date(day.setDate(1));
    firstSaturday.setDate(1 + (6 - firstSaturday.getDay()));
    
    const secondSaturday = new Date(firstSaturday);
    secondSaturday.setDate(firstSaturday.getDate() + 7);

    const thirdSaturday = new Date(firstSaturday);
    thirdSaturday.setDate(firstSaturday.getDate() + 14);

    return (
      (dayOfMonth === secondSaturday.getDate() && day.getMonth() === month) ||
      (dayOfMonth === thirdSaturday.getDate() && day.getMonth() === month)
    );
  };

  // Format time in HH:mm
  const formatTime = (minutes) => {
    if (minutes == null) return '-';
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}`;
  };

  // Handle form submission to fetch attendance data
  const fetchAttendance = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.get('/fetch-attendance', {
        params: {
          empCode: empCode || 'ALL',
          fromDate,
          toDate,
        },
      });

      if (response.data && response.data.data) {
        setAttendanceData(response.data.data);
      } else {
        setAttendanceData([]);
      }
    } catch (err) {
      setError('Error fetching attendance data');
    } finally {
      setLoading(false);
    }
  };

  // Handle calendar date change
  const handleDateChange = (date) => {
    setSelectedDate(date);
    const formattedDate = date.toISOString().split('T')[0];
    setFromDate(formattedDate);
    setToDate(formattedDate);
  };

  useEffect(() => {
    fetchAttendance();
  }, [fromDate, toDate]);

  return (
    <div>
      <h1>Attendance Data</h1>

      <div className="filters">
        <Form.Group controlId="empCode">
          <Form.Label>Employee Code</Form.Label>
          <Form.Control
            type="text"
            value={empCode}
            onChange={(e) => setEmpCode(e.target.value)}
            placeholder="Enter Employee Code"
          />
        </Form.Group>

        <Form.Group controlId="fromDate">
          <Form.Label>From Date</Form.Label>
          <Form.Control
            type="date"
            value={fromDate}
            onChange={(e) => setFromDate(e.target.value)}
          />
        </Form.Group>

        <Form.Group controlId="toDate">
          <Form.Label>To Date</Form.Label>
          <Form.Control
            type="date"
            value={toDate}
            onChange={(e) => setToDate(e.target.value)}
          />
        </Form.Group>

        <Button onClick={fetchAttendance} variant="primary">
          Fetch Attendance
        </Button>
      </div>

      {/* Calendar */}
      <div className="calendar">
        <Calendar
          onChange={handleDateChange}
          value={selectedDate}
          locale="en-GB"
          tileClassName={({ date }) => {
            const dateStr = date.toISOString().split('T')[0];

            // Check attendance for the selected date
            const attendanceRecord = attendanceData.find((record) => record.date === dateStr);

            if (attendanceRecord) {
              if (attendanceRecord.status === 'Absent') return 'absent';
              if (attendanceRecord.status === 'Half Day') return 'half-day';
            }

            // Check for weekends and special Saturdays
            if (isWeekend(date)) {
              if (isSpecialSaturday(date)) return 'specialSaturday';
              return 'weekend';
            }

            return '';
          }}
        />
      </div>

      {loading && <Spinner animation="border" variant="primary" />}
      {error && <Alert variant="danger">{error}</Alert>}

      {/* Table displaying attendance data */}
      {attendanceData.length > 0 ? (
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Name</th>
              <th>Date</th>
              <th>In Time</th>
              <th>Out Time</th>
              <th>Late Mark</th>
              <th>Early Leave</th>
              <th>Overtime</th>
            </tr>
          </thead>
          <tbody>
            {attendanceData.map((record, index) => (
              <tr key={index}>
                <td>{record.name}</td>
                <td>{record.date}</td>
                <td>{formatTime(record.inTime)}</td>
                <td>{formatTime(record.outTime)}</td>
                <td>{formatTime(record.lateMark)}</td>
                <td>{formatTime(record.earlyLeave)}</td>
                <td>{formatTime(record.overtime)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        !loading && <p>No attendance data found</p>
      )}
    </div>
  );
};

export default Attendance;
