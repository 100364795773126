import React, { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import './styles/employeeProfile.css';
import { toast } from 'react-toastify';
import Preview from '../../Assets/Images/profile-prev.png';
import PersonalDetails from './PersonalDetails';
import ContactDetails from './ContactDetails';
import Myteam from './Myteam';
import axios from 'axios';
import { useParams } from 'react-router-dom';

function EmployeeProfile({ userId }) {
  const { id } = useParams(); // Retrieve the userId from the URL
  const [currentView, setCurrentView] = useState('personalDetails');
  const [profileData, setProfileData] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [uploadImg, setUploadImg] = useState(null);

  const user = JSON.parse(localStorage.getItem("user"));
  userId = user ? user._id : null;
console.log(id)
  useEffect(() => {
    const fetchProfileData = async () => {
      if (userId) {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}user-profile/${userId}`);
          setProfileData(response.data);
        } catch (error) {
          console.error("Error fetching profile data:", error);
          toast.error('Failed to fetch profile data!');
        }
      }
    };

    fetchProfileData();
  }, [userId]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.type.startsWith('image/') && file.size <= 5 * 1024 * 1024) {
        const reader = new FileReader();
        reader.onload = () => {
          setUploadImg(reader.result);
          setProfileData((prevState) => ({
            ...prevState,
            ProfileImageUrl: reader.result,
          }));
        };
        reader.readAsDataURL(file);
      } else {
        toast.error('Please upload a valid image (max 5MB)');
      }
    }
  };

  const handleSave = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}user-profile/${userId}`, profileData);
      toast.success('Profile updated successfully!');
    } catch (error) {
      console.error("Error saving profile data:", error);
      toast.error('Failed to save profile data!');
    }
    setIsEditMode(false);
  };

  const handleEdit = () => {
    setIsEditMode(true);
  };

  const renderContent = () => {
    if (!profileData) return <p>Loading...</p>;

    switch (currentView) {
      case 'personalDetails':
        return (
          <PersonalDetails
            data={profileData}
            isEditMode={isEditMode}
            handleImageChange={handleImageChange}
            uploadImg={uploadImg}
          />
        );
      case 'contactDetails':
        return <ContactDetails contactDetails={profileData} isEditMode={isEditMode} />;
      case 'myteam':
        return <Myteam data={profileData?.PermissiontoSee} isEditMode={isEditMode} />;
      default:
        return <PersonalDetails data={profileData} isEditMode={isEditMode} />;
    }
  };

  const formatDate = (date) => {
    if (!date) return '';
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
  };

  return (
    <div className='profile-main-wrap'>
      <div className="profile-box">
        <div className="profile-menu">
          <div className="profilepic">
          <Avatar
  className="profile-pic"
  alt="Profile Picture"
  src={uploadImg || profileData?.ProfileImageUrl || Preview}
  sx={{
    width: 100,
    height: 100,
    objectFit: 'contain', // Ensures the image covers the entire avatar area
  }}
/>
            {isEditMode && (
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                style={{ marginTop: '10px' }}
              />
            )}
            <div className="designation">
              <p>Date of Join: <span>{formatDate(profileData?.DateofJoin)}</span></p>
              <p>Department: <span>{profileData?.Department}</span></p>
              <p>Designation: <span>{profileData?.Designation}</span></p>
              <p>Location: <span>{profileData?.Location}</span></p>
            </div>
          </div>
          <hr />
          <div className="profile-links">
            <ul>
              <li
                className={currentView === 'personalDetails' ? 'active' : ''}
                onClick={() => setCurrentView('personalDetails')}
              >
                Personal Details
              </li>
              <li
                className={currentView === 'contactDetails' ? 'active' : ''}
                onClick={() => setCurrentView('contactDetails')}
              >
                Contact Details
              </li>
            </ul>
          </div>
        </div>

        <div className="profile-details">
          {renderContent()}
          <div className="actions save-data">
  {isEditMode ? (
    <button onClick={handleSave}>Save</button>
  ) : (
    user && user._id === userId && ( // Check if user is logged in and matches the profile being viewed
      <button onClick={handleEdit}>Edit</button>
    )
  )}
</div>

        </div>
      </div>
    </div>
  );
}

export default EmployeeProfile;
