// src/component/Sidenav/Sidenav.js
import React from "react";
import Logo from "../../Assets/Images/logo.png"; // Update the path to your logo
import "./sidenav.css"; // Import CSS for styling
import { NavLink } from "react-router-dom";
import ProfileDropdown from "../ProfileDropdown/ProfileDropdown";

function Sidenav({ userId, userRole, isCollapsed, userDepartment, toggleNav }) {
  const getNavLinkClassName = ({ isActive }) =>
    `menu-item ${isActive ? "active" : ""}`;

  return (
    <div className={`sidebar ${isCollapsed ? "collapsed" : ""}`}>
      <div className="logo">
        <img src={Logo} alt="Logo" />
      </div>
      <hr style={{ margin: "10px 0" }} />
      <div className={`main-menu ${isCollapsed ? "collapsed" : ""}`}>
        <ul>
          {userRole === "administrator" && (
            <li>
              <NavLink to="/admin" className={getNavLinkClassName}>
                <i className="fa-solid fa-user-gear"></i>
                {!isCollapsed && <span>Admin</span>}
              </NavLink>
              <div className="arrow" onClick={toggleNav}></div>
            </li>
          )}
          {userRole === "administrator" && (
            <li>
              <NavLink
                to="/employee-dashboard"
                className={getNavLinkClassName}
              >
                <i className="fa-regular fa-address-card"></i>
                {!isCollapsed && <span>EIM</span>}
              </NavLink>
              <div className="arrow" onClick={toggleNav}></div>
            </li>
          )}
          <li>
            <NavLink to="/" className={getNavLinkClassName}>
              <i className="fa-solid fa-house"></i>
              {!isCollapsed && <span>Dashboard</span>}
            </NavLink>
            <div className="arrow" onClick={toggleNav}></div>
          </li>
          {(userRole === "administrator" || userDepartment === "Sales") && (
            <li>
              <NavLink
                to={`/project-table/${userId}`}
                className={getNavLinkClassName}
              >
                <i className="fa-solid fa-table-list"></i>
                {!isCollapsed && <span>Project Tracker</span>}
              </NavLink>
              <div className="arrow" onClick={toggleNav}></div>
            </li>
          )}
          {(userRole === "administrator" || userDepartment === "Service" || userDepartment === 'Calibration') && (
            <li>
              <NavLink
                to={`/service-tracker/${userId}`}
                className={getNavLinkClassName}
              >
                <i className="fa-solid fa-screwdriver-wrench"></i>
                {!isCollapsed && <span>Service Tracker</span>}
              </NavLink>
              <div className="arrow" onClick={toggleNav}></div>
            </li>
          )}
          <li>
            {/* <NavLink to={`/attendance/`} className={getNavLinkClassName}> */}
            <NavLink to={`/404-error`} className={getNavLinkClassName}>
              <i className="fa-solid fa-clipboard-user"></i>
              {!isCollapsed && <span>Attendance</span>}
            </NavLink>
            <div className="arrow" onClick={toggleNav}></div>
          </li>
          <li>
            <NavLink to={`/organization`} className={getNavLinkClassName}>
              <i className="fa-solid fa-users"></i>
              {!isCollapsed && <span>Organization</span>}
            </NavLink>
            <div className="arrow" onClick={toggleNav}></div>
          </li>
          {userRole === "administrator" && (
            <li>
              <NavLink to={`/my-tasks`} className={getNavLinkClassName}>
                <i className="fa-solid fa-bell"></i>
                {!isCollapsed && <span>Assign Task</span>}
              </NavLink>
              <div className="arrow" onClick={toggleNav}></div>
            </li>
          )}
        </ul>
      </div>
      <hr style={{ margin: "10px 0 20px 0" }} />
      <div className="profile-section">
        <ProfileDropdown isCollapsed={isCollapsed} />
      </div>
    </div>
  );
}

export default Sidenav;
