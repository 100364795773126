import React, { useState, useEffect, useRef } from "react";
import "./addemployee.css";
import Preview from "../../Assets/Images/profile-prev.png";
import { useNavigate } from "react-router-dom";
import { TextField, Autocomplete, Checkbox, FormControlLabel } from "@mui/material";
import { toast } from "react-toastify";

function AddEmployee() {
  const [uploadImg, setUploadImg] = useState(null);
  const fileInputRef = useRef(null);
  const navigate = useNavigate();
  const [isSameAddress, setIsSameAddress] = useState(false);

  // State for form fields
  const [formData, setFormData] = useState({
    FirstName: "",
    LastName: "",
    MiddleName: "",
    DateofJoin: "",
    DOB: "",
    OfficialPhone: "",
    OfficeEmail: "",
    Email: "",
    Phone: "",
    Location: "",
    Designation: "",
    EmergencyPhone: "",
    RelationWithContact: "",
    AadharNumber: "",
    BloodGroup: "",
    Pan: "",
    Role: "user",
    Department: "",
    PolicyNumber: "",
    UAN: "",
    ProfileImageUrl: "",
  });

  const [address, setAddress] = useState({
    street1: "",
    street2: "",
    city: "",
    pincode: "",
    state: "",
    country: "",
  });

  const [permanentAddress, setPermanentAddress] = useState({
    street1: "",
    street2: "",
    city: "",
    pincode: "",
    state: "",
    country: "",
  });

  const [designationSuggestions, setDesignationSuggestions] = useState([]);
  const [departmentSuggestions, setDepartmentSuggestions] = useState([]);
  const roles = ["user", "administrator"];

  // Fetch designations and departments
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}user-designations-departments`);
        if (!response.ok) throw new Error("Failed to fetch user details");

        const { userDetails } = await response.json();
        setDesignationSuggestions([...new Set(userDetails.map((user) => user.designation))]);
        setDepartmentSuggestions([...new Set(userDetails.map((user) => user.department))]);
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    fetchUserDetails();
  }, []);

  // Handle input changes for formData
  const handleChange = (value, field) => {
    if (field === "Designation" && !designationSuggestions.includes(value)) {
      setDesignationSuggestions((prev) => [...prev, value]);
    }
    if (field === "Department" && !departmentSuggestions.includes(value)) {
      setDepartmentSuggestions((prev) => [...prev, value]);
    }

    setFormData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  // Handle address changes
  const handleAddressChange = (e, type, isPermanent = false) => {
    const value = e.target.value.replace(/[^a-zA-Z0-9\s]/g, ""); // Remove special characters
    if (isPermanent) {
      setPermanentAddress((prev) => ({ ...prev, [type]: value }));
    } else {
      setAddress((prev) => ({ ...prev, [type]: value }));
      if (isSameAddress) {
        setPermanentAddress((prev) => ({ ...prev, [type]: value }));
      }
    }
  };

  // Handle same address checkbox
  const handleCheckboxChange = () => {
    setIsSameAddress((prev) => !prev);
    if (!isSameAddress) {
      setPermanentAddress(address);
    } else {
      clearAddress(true);
    }
  };

  // Format address as a string
  const formatAddress = (addressObj) =>
    `${addressObj.street1}, ${addressObj.street2}, ${addressObj.city}, ${addressObj.pincode}, ${addressObj.state}, ${addressObj.country}`;

  // Clear address fields
  const clearAddress = (isPermanent = false) => {
    if (isPermanent) {
      setPermanentAddress({
        street1: "",
        street2: "",
        city: "",
        pincode: "",
        state: "",
        country: "",
      });
    } else {
      setAddress({
        street1: "",
        street2: "",
        city: "",
        pincode: "",
        state: "",
        country: "",
      });
    }
  };

  // Handle image upload
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setUploadImg(reader.result);
        setFormData((prevState) => ({ ...prevState, ProfileImageUrl: reader.result }));
      };
      reader.readAsDataURL(file);
    }
  };

  // Submit the form
  const handleAddEmployee = async () => {
    const formattedAddress = formatAddress(address);
    const formattedPermanentAddress = formatAddress(permanentAddress);

    setFormData((prev) => ({
      ...prev,
      Address: formattedAddress,
      PermanentAddress: formattedPermanentAddress,
    }));

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}addemployee`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          ...formData,
          Address: formattedAddress,
          PermanentAddress: formattedPermanentAddress,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error("Error Response:", errorData);
        throw new Error("Network response was not ok");
      }

      toast.success("Employee Added Successfully");
      navigate("/organization");
    } catch (error) {
      console.error("There was a problem adding the employee:", error);
    }
  };

  return (
    <div className="add-employee-wrap">
      <h1 className="page-title">Employee Details</h1>
      <div className="basic-info">
        <div className="employee-img">
          <label htmlFor="fileInput">
            <img src={uploadImg || Preview} alt="Profile" />
          </label>
          <input
            type="file"
            id="fileInput"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleImageChange}
          />
          <button onClick={() => fileInputRef.current.click()}>Add Image</button>
        </div>
        <div className="employee-form">
          <TextField label="First Name" value={formData.FirstName} onChange={(e) => handleChange(e.target.value, "FirstName")} />
          <TextField label="Middle Name" value={formData.MiddleName} onChange={(e) => handleChange(e.target.value, "MiddleName")} />
          <TextField label="Last Name" value={formData.LastName} onChange={(e) => handleChange(e.target.value, "LastName")} />
          <TextField
            label="Date of Birth"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={formData.DOB}
            onChange={(e) => handleChange(e.target.value, "DOB")}
          />
          <TextField
            label="Date of Joining"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={formData.DateofJoin}
            onChange={(e) => handleChange(e.target.value, "DateofJoin")}
          />
          <Autocomplete
            freeSolo
            options={designationSuggestions.filter(Boolean)} // Remove null/undefined values
            value={formData.Designation}
            onChange={(event, newValue) => {
              if (newValue && !designationSuggestions.includes(newValue)) {
                setDesignationSuggestions((prev) => [...prev, newValue]);
              }
              handleChange(newValue, "Designation");
            }}
            renderInput={(params) => (
              <TextField {...params} label="Designation" helperText="Type a new designation to add it to the list" />
            )}
          />

          <Autocomplete
            freeSolo
            options={departmentSuggestions.filter(Boolean)} // Remove null/undefined values
            value={formData.Department}
            onChange={(event, newValue) => {
              if (newValue && !departmentSuggestions.includes(newValue)) {
                setDepartmentSuggestions((prev) => [...prev, newValue]);
              }
              handleChange(newValue, "Department");
            }}
            renderInput={(params) => (
              <TextField {...params} label="Department" helperText="Type a new department to add it to the list" />
            )}
          />


          <TextField label="Official Phone" value={formData.OfficialPhone} onChange={(e) => handleChange(e.target.value, "OfficialPhone")} />
          <TextField label="Office Email" value={formData.OfficeEmail} onChange={(e) => handleChange(e.target.value, "OfficeEmail")} />
          <TextField label="Email" value={formData.Email} onChange={(e) => handleChange(e.target.value, "Email")} />
          <TextField label="Phone" value={formData.Phone} onChange={(e) => handleChange(e.target.value, "Phone")} />
          <TextField label="Blood Group" value={formData.BloodGroup} onChange={(e) => handleChange(e.target.value, "BloodGroup")} />
          <TextField
            label="Emergency Phone"
            value={formData.EmergencyPhone}
            onChange={(e) => handleChange(e.target.value, "EmergencyPhone")}
          />
          <TextField
            label="Relation with Contact"
            value={formData.RelationWithContact}
            onChange={(e) => handleChange(e.target.value, "RelationWithContact")}
          />
          <TextField label="Aadhar Number" value={formData.AadharNumber} onChange={(e) => handleChange(e.target.value, "AadharNumber")} />
          <TextField label="PAN Number" value={formData.Pan} onChange={(e) => handleChange(e.target.value, "Pan")} />
          <TextField label="Policy Number" value={formData.PolicyNumber} onChange={(e) => handleChange(e.target.value, "PolicyNumber")} />
          <TextField label="UAN" value={formData.UAN} onChange={(e) => handleChange(e.target.value, "UAN")} />
          <Autocomplete
            options={roles}
            value={formData.Role}
            onChange={(event, newValue) => handleChange(newValue, "Role")}
            renderInput={(params) => <TextField {...params} label="Role" />}
          />
        </div>
      </div>
      <div className="address-section">
        <h2>Current Address</h2>
        <TextField label="Street 1" value={address.street1} onChange={(e) => handleAddressChange(e, "street1")} />
        <TextField label="Street 2" value={address.street2} onChange={(e) => handleAddressChange(e, "street2")} />
        <TextField label="City" value={address.city} onChange={(e) => handleAddressChange(e, "city")} />
        <TextField label="Pincode" value={address.pincode} onChange={(e) => handleAddressChange(e, "pincode")} />
        <TextField label="State" value={address.state} onChange={(e) => handleAddressChange(e, "state")} />
        <TextField label="Country" value={address.country} onChange={(e) => handleAddressChange(e, "country")} />
        <FormControlLabel
          control={<Checkbox checked={isSameAddress} onChange={handleCheckboxChange} />}
          label="Same as Current Address"
        />
        <h2>Permanent Address</h2>
        <TextField label="Street 1" value={permanentAddress.street1} onChange={(e) => handleAddressChange(e, "street1", true)} />
        <TextField label="Street 2" value={permanentAddress.street2} onChange={(e) => handleAddressChange(e, "street2", true)} />
        <TextField label="City" value={permanentAddress.city} onChange={(e) => handleAddressChange(e, "city", true)} />
        <TextField label="Pincode" value={permanentAddress.pincode} onChange={(e) => handleAddressChange(e, "pincode", true)} />
        <TextField label="State" value={permanentAddress.state} onChange={(e) => handleAddressChange(e, "state", true)} />
        <TextField label="Country" value={permanentAddress.country} onChange={(e) => handleAddressChange(e, "country", true)} />
      </div>
      <button className="add-employee-button" onClick={handleAddEmployee}>
        Add Employee
      </button>
    </div>
  );
}

export default AddEmployee;
